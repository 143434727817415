<template>
  <div class="container_con">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="采购计划单查询明细" name="1">
          <Accep ref="model1" />
        </el-tab-pane>
        <el-tab-pane label="采购验收单查询明细" name="2">
          <Single ref="model2" />
        </el-tab-pane>
        <el-tab-pane label="采购入库单查询明细" name="3">
          <Warrant ref="model3" />
        </el-tab-pane>
        <el-tab-pane label="采购退货单查询明细" name="4">
          <Backs ref="model4" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Accep from "../../components/purch/accep.vue";
import Single from "../../components/purch/single.vue";
import Warrant from "../../components/purch/warrant.vue";
import Backs from "../../components/purch/backs.vue";
export default {
  name: "purchaseSlect",
  components: {
    Accep,
    Single,
    Warrant,
    Backs
  },
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    this.activeName = sessionStorage.getItem("pur_active")
      ? sessionStorage.getItem("pur_active")
      : this.activeName;
    this.$nextTick(()=>{
        this.$refs['model'+this.activeName].getList()
    }) 
    this.old_active=this.activeName;
  },
  watch: {
    $route(to,form) {
        this.$refs['model'+this.old_active].save_filter();
    },
  },
  methods: {
    handleClick() {
      sessionStorage.setItem("pur_active", this.activeName);
      this.$refs['model'+this.activeName].getList();
      this.$refs['model'+this.old_active].save_filter();
      this.old_active=this.activeName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
