<template>
    <div class="container_con">
        <div class="new_search_box">
            <div style="margin-top:0px">
                <span class="mr_20">
                    编号：
                    <el-input v-model="queryParams.purchase_plan_no" placeholder="请输入计划单编号" style="width:200px" />
                </span>
                <span class="mr_20">
                    供货单位：
                    <el-select v-model="queryParams.merchant_id" placeholder="输入供货单位名称或助记码" clearable remote
                        :remote-method="remoteMethod" style="width:220px" filterable>
                        <el-option :label="item.merchant_name" v-for="(item, index) in jhdwlist" :key="index"
                            :value="item.id"></el-option>
                    </el-select>
                </span>
                <span class="mr_20">
                    时间：
                    <el-date-picker v-model="queryParams.time" type="daterange" value-format="YYYY-MM-DD"
                        placeholder="请选择时间" range-separator="至" style="width: 300px;" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </span>
                <span class="mr_20">
                    采购员：
                    <el-select v-model="queryParams.admin_id" placeholder="输入采购员姓名搜索" clearable style="width:220px"
                        filterable>
                        <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code" v-for="(item, index) in cglist"
                            :key="index" :value="item.id"></el-option>
                    </el-select>
                </span>
                <span class="mr_20">
                    产品：
                    <el-input v-model="queryParams.product_no" placeholder="请输入产品编号/品名" style="width:200px" />
                </span>
                <el-button type="primary" @click="handleQuery()">搜索</el-button>

                <el-button @click="resetQuery()">重置</el-button>
            </div>
        </div>
        <div class="search">
            <div>
                <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/purchaseSlect/export')"
                    :loading="is_export" @click="try_export">导出</el-button>
            </div>
        </div>
        <div style="margin-top:20px">
            <el-table :data="list" v-loading="loading" style="width: 100%">
                <el-table-column prop="purchase_plan_date" label="日期" width="100" />
                <el-table-column prop="purchase_plan_no" label="计划单号" width="100" />
                <el-table-column label="单位编号">
                    <template v-slot="scope">
                        {{ scope.row.merchant.merchant_no }}
                    </template>
                </el-table-column>
                <el-table-column label="供应商单位名称" width="120">
                    <template v-slot="scope">
                        {{ scope.row.merchant.merchant_name }}
                    </template>
                </el-table-column>
                <el-table-column label="产品编号" sortable :sort-method="formatterproduct_no">
                    <template v-slot="scope">
                        {{ scope.row.product.product_no }}
                    </template>
                </el-table-column>
                <el-table-column label="品名">
                    <template v-slot="scope">
                        {{ scope.row.product.product_name }}
                    </template>
                </el-table-column>
                <el-table-column label="单位">
                    <template v-slot="scope">
                        {{ scope.row.product.pack_unit_name }}
                    </template>
                </el-table-column>
                <el-table-column label="数量" sortable :sort-method="formatterproduct_num">
                    <template v-slot="scope">
                        {{ scope.row.product_num }}
                    </template>
                </el-table-column>
                <el-table-column label="单价" sortable :sort-method="formatterproduct_price">
                    <template v-slot="scope">
                        {{ scope.row.product_price }}
                    </template>
                </el-table-column>
                <el-table-column label="金额" sortable :sort-method="formatteramount">
                    <template v-slot="scope">
                        {{ scope.row.amount }}
                    </template>
                </el-table-column>

                <el-table-column label="产地">
                    <template v-slot="scope">
                        {{ scope.row.product.origin }}
                    </template>
                </el-table-column>

                <el-table-column label="注册证名称">
                    <template v-slot="scope">
                        {{ scope.row.product.register_cert_name }}
                    </template>
                </el-table-column>
                <el-table-column label="注册证号">
                    <template v-slot="scope">
                        {{ scope.row.product.register_cert_no }}
                    </template>
                </el-table-column>
                <el-table-column label="备注" :show-overflow-tooltip="true">
                    <template v-slot="scope">
                        {{ scope.row.product.remark }}
                    </template>
                </el-table-column>
                <el-table-column label="采购员">
                    <template v-slot="scope">
                        {{ scope.row.admin_name }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="50">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="handleDetail(scope.row)"
                            v-if="have_auth('/purchaseSlect/view')">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
        @pagination="getList" />
</template>

<script>
export default {
    name: "validity",
    data() {
        return {
            // 列表模板
            loading: false,
            // 查询参数
            queryParams: {
                page: 1,
                size: 10,
                purchase_plan_no: '',
                time: ''
            },
            total: 0,
            list: [],
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            jhdwlist: [],
            cglist: [],
            is_export: false,
        };
    },

    created() {
        this.queryParams = window['purchaseSlect_accep'] || {
            page: 1,
            size: 10,
            purchase_plan_no: '',
            time: ''
        }
        this.remoteMethod();
        this.get_cglist()
    },
    methods: {
        formatterproduct_no(a,b){
            return a.product.product_no.localeCompare(b.product.product_no)
        },
        formatteramount(a, b) {
            return Number(a.amount) - Number(b.amount);
        },
        formatterproduct_price(a, b) {
            return Number(a.product_price) - Number(b.product_price);
        },
        formatterproduct_num(a, b) {
            return Number(a.product_num) - Number(b.product_num);
        },
        save_filter() {
            window['purchaseSlect_accep'] = this.queryParams
        },
        try_export() {
            if (this.is_export) { return false; }
            this.is_export = true;
            let _data = { ...this.queryParams };
            _data.startTime = _data.time ? _data.time[0] : '';
            _data.endTime = _data.time ? _data.time[1] : '';
            this.$httpGet("/backend/PurchasePlanProduct/export", _data).then((res) => {
                if (res.status == 200) {
                    var a = document.createElement('a');
                    let name = res.data.file.split('/')
                    var fileName = name[name.length - 1];
                    a.download = fileName;
                    a.href = this.$http + '/' + res.data.file;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    this.$message.error(res.message);
                }
                this.is_export = false;
            }).catch((err) => {
                console.log(err);
                this.is_export = false;
            });
        },
        resetQuery() {
            this.queryParams = {
                page: 1,
                size: 10,
                purchase_plan_no: '',
                time: ''
            }
            this.getList()
        },
        remoteMethod(keyword) {
            this.$httpGet("/backend/Merchant/merchant_purchase", { size: 10000, title: keyword }).then((res) => {
                if (res.status == 200) {
                    this.jhdwlist = res.data.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        get_cglist(keyword) {
            this.$httpGet("/backend/sys.Admin/purchaseList", { size: 10000000 }).then((res) => {
                if (res.status == 200) {
                    this.cglist = res.data.data || [];
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        // 查看详情
        handleDetail(row) {
            this.$router.push('/purchaseSlectDetail?id=' + row.id)
        },
        /** 查询列表 */
        getList() {
            this.loading = true;
            let _data = { ...this.queryParams };
            _data.startTime = _data.time ? _data.time[0] : '';
            _data.endTime = _data.time ? _data.time[1] : '';
            this.$httpGet("/backend/PurchasePlanProduct/index", _data).then((res) => {
                if (res.status == 200) {
                    this.loading = false;
                    this.list = res.data.data;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        }
    },
};
</script>
<style lang="scss" scoped></style>
